import API from '@aws-amplify/api'

API.configure({
  endpoints: [
    {
      name: 'API',
      endpoint: process.env.REACT_APP_API_URL,
      region: process.env.REACT_APP_AWS_REGION
    }
  ]
})
