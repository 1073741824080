import React, { useState } from 'react'
import { Link, Flex, Box } from 'rebass'
import API from '@aws-amplify/api'
import AuthLayout from '../layout/AuthLayout'
import NamespaceForm from '../form/NamespaceForm'

const LoginView = () => {
  const [value, setValue] = useState('')
  const targetTld = window.location.hostname.split('sharecover.').pop()
  const onSubmit = (values, { setSubmitting }) => {
    API.post(
      'API',
      '/thorr/namespace',
      {
        headers: {
          'Content-Type': 'application/json'
        },
        body: values
      }
    ).then(response => {
      setSubmitting(false)
      window.location.href = `https://${response}.sharecover.${targetTld}/login`
    }).catch(err => {
      setSubmitting(false)
      setValue(err)
    })
  }

  return (
    <AuthLayout>
      {value && <Box my={4} p={2} bg="lightGrey">{value}</Box>}
      <NamespaceForm onSubmit={onSubmit} />
      <Flex justifyContent="center" my={4}>
        <Link
          as="a"
          href={`https://sharecover.${targetTld}`}
        >
          Back to home
        </Link>
      </Flex>
    </AuthLayout>
  )
}

export default LoginView
