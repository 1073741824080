import React from 'react'
import { Flex, Box, Heading, Image } from 'rebass'
import logo from '../../assets/logo-vertical-orange.svg'

const AuthLayout = ({ title, children }) => (
  <Flex alignItems="center" flexDirection="column" m={[4, 6]}>
    <Image src={logo} alt="ShareCover" sx={{ width: 100 }} />
    <Heading fontSize={2} mt={4}>{title}</Heading>
    <Box width={1} sx={{ maxWidth: 300 }}>
      {children}
    </Box>
  </Flex>
)

AuthLayout.defaultProps = {
  title: 'Login'
}

export default AuthLayout
