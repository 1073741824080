import React from 'react'
import { Box, Button, Image } from 'rebass'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Label, Input } from '@rebass/forms'
import Loading from '../../assets/loading.svg'

const NamespaceForm = props => (
    <Formik
        initialValues={{ email: '' }}
        validate={values => {
            let errors = {}

            if (!values.email) {
                errors.email = 'Required'
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address'
            }

            return errors
        }}
        {...props}
    >
        {({ isSubmitting }) => (
            <Box as={Form} width={1} sx={{ my: 4 }}>
                <Label htmlFor='email'>Email</Label>
                <Input
                    id='email'
                    name='email'
                    type='email'
                    placeholder='jane@example.com'
                    as={Field}
                />
                <ErrorMessage name="email" component="div" />
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    width={1}
                    sx={{
                        my: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {!isSubmitting && 'Next'}
                    {isSubmitting && <Image src={Loading} alt="Loading" sx={{ width: 30, height: 30 }} />}
                </Button>
            </Box>
        )}
    </Formik>
)

export default NamespaceForm
