const button = {
    bg: 'secondary',
    ':hover,:focus,.active': {
        bg: 'primary',
        color: 'white',
    },
    color: 'white',
    p: '12px 20px',
    textAlign: 'center',
    fontWeight: 500,
    borderRadius: 0
}

export default ({
    breakpoints: [
        '40em', '52em', '64em',
    ],
    colors: {
        text: '#000',
        background: '#fff',
        primary: '#EF801D',
        primaryHighlight: '#FF9B06',
        secondary: '#3656DC',
        secondaryHighlight: '#3951B5',
        black: '#000000',
        darkGrey: '#151E32',
        lightGrey: '#E2E8F4',
        snowWhite: '#F7F9FC',
        white: '#fff',
        error: '#FF0000',
        success: '#36DC69'
    },
    fonts: {
        body: 'Gordita, system-ui, sans-serif',
        heading: 'inherit',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [
        16, 24, 32, 48, 60, 72
    ],
    fontWeights: {
        body: 400,
        heading: 500,
        bold: 600,
    },
    forms: {
      input: {
        border: '2px solid',
        borderColor: 'lightGrey',
        outline: 'none',
        ':focus': {
            borderColor: 'primary',
        },
        px: 2,
        py: 15
      },
      textarea: {
        border: '2px solid',
        borderColor: 'lightGrey',
        outline: 'none',
        ':focus': {
            borderColor: 'primary',
        },
        px: 2,
        py: 15
      },
      select: {
        border: '2px solid',
        borderColor: 'lightGrey',
        outline: 'none',
        ':focus': {
            borderColor: 'primary',
        },
        px: 2,
        py: 15
      },
      label: {},
      radio: {},
      checkbox: {},
    },
    lineHeights: {
        body: 1.9,
        heading: 1.7,
    },
    space: [0, 10, 20, 30, 40, 60, 120],
    sizes: {
        avatar: 48,
    },
    radii: {
        default: 0,
        circle: 99999,
    },
    shadows: {
        card: '0 0 4px rgba(0, 0, 0, .125)',
    },
    text: {
        hero: {
            heading: {
                fontSize: [3, 4, 5],
                fontWeight: 600,
                lineHeight: 1.3
            },
            subheading: {
                maxWidth: 500,
                fontSize: [1, 2],
                fontWeight: 500,
                lineHeight: 1.5
            }
        },
        heading: {
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
            fontSize: [1, 2],
        },
        subheading: {
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
            fontSize: [0, 1],
        },
        display: {
            fontFamily: 'heading',
            fontWeight: 'heading',
            lineHeight: 'heading',
            fontSize: 0,
        },
        question: {
            fontSize: [0],
            border: 'none',
            appearance: 'none',
            outline: 'none',
            cursor: 'pointer',
            textAlign: 'left'
        },
        dropdown: {
            fontFamily: 'heading',
            fontWeight: 'heading',
            lineHeight: 'heading',
            fontSize: 20
        }
    },
    variants: {
        answer: {
            ml: 1,
            mb: 4,
            pl: 2,
            borderLeft: '3px solid',
            borderLeftColor: 'primaryHighlight',
        },
        link: {
            color: 'secondary',
            ':hover,:focus,&.active': {
                color: 'primary',
            },
            fontWeight: 500,
            lineHeight: 1.2
        },
        outline: {
            border: 1,
            borderStyle: 'solid',
            borderColor: 'secondary',
            ':hover,:focus,.active': {
                borderColor: 'primary',
                color: 'primary',
            },
            color: 'secondary',
            p: '12px 20px',
            textAlign: 'center',
            fontWeight: 500
        },
        button,
        buttonSmall: {
            ...button,
            px: 3,
            py: 8
        },
        text: {
            fontSize: [2],
            fontWeight: 'bold',
        },
        fixed: {
            maxWidth: 1400,
            mx: 'auto',
            px: [2, 3]
        },
        fixedResponsive: {
            maxWidth: [1400, 1400, 'none'],
            mx: 'auto',
            px: [2, 3]
        },
        card: {
            bg: 'white',
            flexDirection: 'column',
            m: [2, 4],
            mx: 'auto',
            p: [3, 4, '50px'],
            border: '1px solid',
            borderColor: 'lightGrey',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.24)',
            width: ['auto', 'auto', '580px'],
            maxWidth: '100%'
        },
        hero: {
            position: 'relative',
            background: 'linear-gradient(140deg, #DB511E, #FCB016 60%)',
            color: 'white',
            mb: -4,
            '.content': {
                pt: [120, 140, 160],
                pb: [120, 140, 160],
                '> *': {
                    my: [1]
                }
            },
            '> *': {
                pb: 80
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: '-1px',
                right: 0,
                left: 0,
                height: 100,
                bg: 'white',
                clipPath: [
                    'ellipse(100% 100% at 50% 120%)',
                    'ellipse(80% 100% at 50% 120%)',
                    'ellipse(60% 100% at 50% 120%)'
                ],
            },
        },
        dropdown: {
            position: 'absolute',
            p: 3,
            bg: 'white',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.24)',
            transform: 'translate(-50%)',
            left: '50%',
            minWidth: 300
        }
    },
    buttons: {
        primary: {
            ...button
        },
        outline: {
            variant: 'buttons.primary',
            color: 'primary',
            bg: 'transparent',
            boxShadow: 'inset 0 0 2px',
        },
        secondary: {
            variant: 'buttons.primary',
            color: 'background',
            bg: 'secondary',
        },
        link: {
            color: 'secondary',
            ':hover,:focus,&.active': {
                color: 'primary',
            },
            fontFamily: 'body',
            cursor: 'pointer',
            outline: 'none',
            fontWeight: 500,
            bg: 'transparent'
        },
        icon: {
            bg: 'transparent',
            p: 0
        }
    },
    styles: {
        root: {
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
        }
    }
})
