import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { Global } from '@emotion/core'
import theme from './theme'
import globalStyles from './globalStyles'

import LoginView from '../view/LoginView'

const App = () => (
  <ThemeProvider theme={theme}>
    <Global styles={globalStyles} />
    <LoginView />
  </ThemeProvider>
)

export default App
