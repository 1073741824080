import { css } from '@emotion/core'

import GorditaLightWoff2 from '../../fonts/GorditaLight.woff2'
import GorditaLightWoff from '../../fonts/GorditaLight.woff'
import GorditaLightOtf from '../../fonts/GorditaLight.otf'
import GorditaMediumWoff2 from '../../fonts/GorditaMedium.woff2'
import GorditaMediumWoff from '../../fonts/GorditaMedium.woff'
import GorditaMediumOtf from '../../fonts/GorditaMedium.otf'
import GorditaLightItalicWoff2 from '../../fonts/GorditaLight-Italic.woff2'
import GorditaLightItalicWoff from '../../fonts/GorditaLight-Italic.woff'
import GorditaLightItalicOtf from '../../fonts/GorditaLight-Italic.otf'
import GorditaBoldItalicWoff2 from '../../fonts/GorditaBold-Italic.woff2'
import GorditaBoldItalicWoff from '../../fonts/GorditaBold-Italic.woff'
import GorditaBoldItalicOtf from '../../fonts/GorditaBold-Italic.otf'
import GorditaBlackWoff2 from '../../fonts/GorditaBlack.woff2'
import GorditaBlackWoff from '../../fonts/GorditaBlack.woff'
import GorditaBlackOtf from '../../fonts/GorditaBlack.otf'
import GorditaRegularWoff2 from '../../fonts/GorditaRegular.woff2'
import GorditaRegularWoff from '../../fonts/GorditaRegular.woff'
import GorditaRegularOtf from '../../fonts/GorditaRegular.otf'
import GorditaMediumItalicWoff2 from '../../fonts/GorditaMedium-Italic.woff2'
import GorditaMediumItalicWoff from '../../fonts/GorditaMedium-Italic.woff'
import GorditaMediumItalicOtf from '../../fonts/GorditaMedium-Italic.otf'
import GorditaBlackBlackItalicWoff2 from '../../fonts/GorditaBlack-BlackItalic.woff2'
import GorditaBlackBlackItalicWoff from '../../fonts/GorditaBlack-BlackItalic.woff'
import GorditaBlackBlackItalicOtf from '../../fonts/GorditaBlack-BlackItalic.otf'
import GorditaRegularItalicWoff2 from '../../fonts/GorditaRegular-Italic.woff2'
import GorditaRegularItalicWoff from '../../fonts/GorditaRegular-Italic.woff'
import GorditaRegularItalicOtf from '../../fonts/GorditaRegular-Italic.otf'
import GorditaBoldWoff2 from '../../fonts/GorditaBold.woff2'
import GorditaBoldWoff from '../../fonts/GorditaBold.woff'
import GorditaBoldOtf from '../../fonts/GorditaBold.otf'

export default theme => css`
    // 100 Thin

    // 200 Extra Light

    // 300 Light
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaLightWoff2}) format('woff2'),
            url(${GorditaLightWoff}) format('woff'),
            url(${GorditaLightOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaLightItalicWoff2}) format('woff2'),
            url(${GorditaLightItalicWoff}) format('woff'),
            url(${GorditaLightItalicOtf}) format('opentype');
        font-weight: 300;
        font-style: italic;
    }

    // 400 Normal
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaRegularWoff2}) format('woff2'),
            url(${GorditaRegularWoff}) format('woff'),
            url(${GorditaRegularOtf}) format('opentype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaRegularItalicWoff2}) format('woff2'),
            url(${GorditaRegularItalicWoff}) format('woff'),
            url(${GorditaRegularItalicOtf}) format('opentype');
        font-weight: normal;
        font-style: italic;
    }

    // 500 Medium
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaMediumWoff2}) format('woff2'),
            url(${GorditaMediumWoff}) format('woff'),
            url(${GorditaMediumOtf}) format('opentype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaMediumItalicWoff2}) format('woff2'),
            url(${GorditaMediumItalicWoff}) format('woff'),
            url(${GorditaMediumItalicOtf}) format('opentype');
        font-weight: 500;
        font-style: italic;
    }

    // 600 Semi Bold

    // 700 Bold
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaBoldWoff2}) format('woff2'),
            url(${GorditaBoldWoff}) format('woff'),
            url(${GorditaBoldOtf}) format('opentype');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaBoldItalicWoff2}) format('woff2'),
            url(${GorditaBoldItalicWoff}) format('woff'),
            url(${GorditaBoldItalicOtf}) format('opentype');
        font-weight: bold;
        font-style: italic;
    }

    // 800 Extra Bold

    // 900 Ultra Bold
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaBlackWoff2}) format('woff2'),
            url(${GorditaBlackWoff}) format('woff'),
            url(${GorditaBlackOtf}) format('opentype');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gordita';
        src: url(${GorditaBlackBlackItalicWoff2}) format('woff2'),
            url(${GorditaBlackBlackItalicWoff}) format('woff'),
            url(${GorditaBlackBlackItalicOtf}) format('opentype');
        font-weight: 900;
        font-style: italic;
    }

    body {
        margin: 0;
        padding: 0;

        font-family: Gordita, system-ui, sans-serif;
        font-size: ${theme.fontSizes[0]};
        font-weight: normal;
        line-height: 30px;
        color: #222;
    }

    a {
        text-decoration: none;
        transition: all 0.2s ease-out;

        color: ${theme.colors.secondary};
        font-weight: ${theme.variants.link.fontWeight};

        :hover, :active {
            color: ${theme.colors.primary};
        }
    }

    ul {
        list-style-type: none;
        padding-inline-start: 20px;
    }

    ul li {
        padding: 5px 0;
    }

    ul li > p {
        display: inline;
    }

    ul li:before {
        background-color: ${theme.colors.primary};
        content: "";
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 3px;
        height: 7px;
        width: 7px;
    }

    img {
        max-width: 100%;
    }
`
